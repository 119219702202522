import { Route, Switch } from 'react-router-dom';

import { useEffect } from 'react';

import { notification } from 'antd';
import { CustomError, Service } from 'uai-graos-sdk';

import UserDetailsView from './views/UserDetails.view';
import HomeView from './views/Home.view';
import UserCreateView from './views/UserCreate.view';
import UserEditView from './views/UserEdit.view';
import UserListView from './views/UserList.view';
import BidCreateView from './views/BidCreate.view';
import BuyOffersListView from './views/BidsList.view';

import PaymentSell from './features/PaymentSell';
import CreateMarketData from './views/CreateMarketData';
import NegotiationsList from './views/NegotiationsList';
import BidEditView from './views/BidEdit.view';
import Login from './features/Login/Login';
import NegotiationsCreate from './views/NegotiationsCreate';
import ListMarketData from './views/ListMarketData';
import ContractCreateForm from './features/ContractCreateForm';
import CounterBidCreateForm from './features/CounterBidCreateForm';
import CounterBidList from './features/CounterBidList';
import ConcractUploadForm from './features/ConcractUploadForm';
import ConcractSignForm from './features/ContractSignForm';
import OperationList from './features/OperationList';
import OperationView from './features/OperationView';
import BarterList from './features/BarterList';
import RepresentativeList from './features/RepresentativeList';
import RepresentativeForm from './features/RepresentativeForm';
import BarterQuotationDetails from './views/BarterQuotationDetails.view';
import PreRegisterList from './features/PreRegisterList';
import LeadsCRM from './features/LeadsCRM';

export default function Routes() {
  useEffect(() => {
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    Service.setBaseUrl(`${baseUrl}/v1`);

    window.onunhandledrejection = ({ reason }) => {
      if (reason instanceof CustomError) {
        if (reason.message) {
          notification.error({
            message: 'Erro!',
            description: reason.message,
          });
          return;
        }
        notification.error({
          message: 'Houve um erro durante a ação',
        });
        return;
      }
      notification.error({
        message: reason,
      });
    };

    return () => {
      window.onunhandledrejection = null;
    };
  }, []);

  return (
    <Switch>
      <Route path={'/'} exact component={HomeView} />
      <Route
        path={'/users/register'}
        exact
        component={UserCreateView}
      />
      <Route
        path={'/users/:id'}
        exact
        component={UserDetailsView}
      />
      <Route
        path={'/users/edit/:id'}
        exact
        component={UserEditView}
      />
      <Route
        path={'/users'}
        exact
        component={UserListView}
      />
      <Route
        path={'/bids'}
        exact
        component={BuyOffersListView}
      />
      <Route
        path={'/bids/register'}
        exact
        component={BidCreateView}
      />
      <Route
        path={'/bids/edit/:id'}
        exact
        component={BidEditView}
      />
      <Route
        path={'/bid-seller/register'}
        exact
        component={PaymentSell}
      />
      <Route
        path={'/quotes/register'}
        exact
        component={CreateMarketData}
      />
      <Route
        path={'/quotes'}
        exact
        component={ListMarketData}
      />
      <Route
        path={'/negotiations'}
        exact
        component={NegotiationsList}
      />
      <Route
        path={'/negotiations/register'}
        exact
        component={NegotiationsCreate}
      />
      <Route
        path={'/contract'}
        exact
        component={ContractCreateForm}
      />
      <Route
        path={'/contract/sign/:id'}
        exact
        component={ConcractSignForm}
      />
      <Route
        path={'/operations'}
        exact
        component={OperationList}
      />
      <Route
        path={'/operations/:id'}
        exact
        component={OperationView}
      />
      <Route
        path={'/contract/upload'}
        exact
        component={ConcractUploadForm}
      />
      <Route
        path={'/counter-bid/register'}
        exact
        component={CounterBidCreateForm}
      />
      <Route
        path={'/counter-bid'}
        exact
        component={CounterBidList}
      />
      <Route
        path={'/barter'}
        exact
        component={BarterList}
      />
      <Route
        path={'/barter/:id'}
        exact
        component={BarterQuotationDetails}
      />
      <Route path={'/login'} exact component={Login} />
      <Route
        path={'/representative'}
        exact
        component={RepresentativeList}
      />
      <Route
        path={'/representative/register'}
        exact
        component={RepresentativeForm}
      />
      <Route
        path={'/company'}
        exact
        component={PreRegisterList}
      />
      <Route
        path={'/leads-crm'}
        exact
        component={LeadsCRM}
      />
    </Switch>
  );
}
