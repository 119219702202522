/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import {
  Fac,
  FacService,
  FacHistory,
  FacHistoryService,
} from 'uai-graos-sdk';
import { notification } from 'antd';

export default function useFac() {
  const [facs, setFacs] = useState<Fac.Fac[]>();
  const [oldFacStatus, setOldFacStatus] = useState<string>('');
  const [facHistory, setFacHistory] = useState<FacHistory.FacHistory[]>();
  const [pagination, setPagination] = useState<Fac.Query>({
    page: 0,
    size: 150,
  });
  const [editFacForm, setEditFacForm] = useState<Fac.Fac>({
    name: '',
    status: undefined,
    phoneNumber: '',
  });
  const [isLoading, setIsLoading] =
    useState<boolean>(false);

  useEffect(() => {
    getAllFacs(pagination);
  }, []);

  const createFacHistory = async (
    facId: string,
    body: Fac.Fac,
    oldStatus?: string,
    comments?: string
  ) => {

    if(oldStatus === body.status){
      body = {
        ...body,
        status: undefined
      }
      oldStatus = undefined
    }
    const facHistoryUpdateBody: FacHistory.FacHistory = {
      ...body,
      comments,
      oldStatus,
      newStatus: body.status,
    };
    await FacHistoryService.createFacHistory(
      facId,
      facHistoryUpdateBody
    ).then(() => getFacHistory(facId));
  };

  const getFacHistory = async (facId: string) => {
    await FacHistoryService.getFacHistory(facId).then(setFacHistory);
  };

  const getAllFacs = async (query: Fac.Query) => {
    setIsLoading(true);
    await FacService.getAllFacs(pagination).then(
      (resp: Fac.Paged) => {
        const { content, totalElements } = resp;
        setFacs(content);
        setPagination((prevPagination) => ({
          ...prevPagination,
          total: totalElements,
          showTotal: (total: number, range: any) =>
            `${range[0]}-${range[1]} de ${total} registros`,
          page: query.page,
        }));
      }
    );
    setIsLoading(false);
  };

  const updateFac = async (
    id: string,
    input: Fac.Fac,
    oldStatus: string,
    comments: string
  ) => {
    await Promise.all([
      FacService.updateFac(id, input),
      createFacHistory(id, input, oldStatus, comments),
    ]).then(() => {
      notification.success({
        message: 'Sucesso!',
        description: 'Lead Atualizada!',
      });
    });
    window.location.href = '/leads-crm';
  };

  const updateFacStatus = async (
    id: string,
    input: Fac.UpdateStatusInput
  ) => {
    await Promise.all([ FacService.updateFacStatus(id, input), createFacHistory(id, input, oldFacStatus)]).then(() => {
      notification.success({
        message: 'Sucesso!',
        description: 'Lead Atualizada!',
      });
    });
  };
  return {
    facs,
    isLoading,
    updateFacStatus,
    editFacForm,
    facHistory,
    setEditFacForm,
    updateFac,
    getFacHistory,
    setOldFacStatus,
    createFacHistory,
    oldFacStatus
  };
}
