import { mask, unMask } from 'remask'

export const formatPhoneInput = (value: string) => {
    const maskedPhone = mask(unMask(value), [
        '(99) 9999-9999',
        '(99) 99999-9999',
    ]);
    return maskedPhone;
};
export const formatPhoneTable = (value?: string) => {
    const phoneNumber = value?.replace(/\s/g, '');
    const phoneNumberWithoutPrefix = phoneNumber?.replace(/^\+55/, '')?.replace(/\D/g, '')
    const maskedPhone = mask(unMask(phoneNumberWithoutPrefix), [
        '(99) 9999-9999',
        '(99) 99999-9999',
    ]);
    return maskedPhone;
};

