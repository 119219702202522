/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';
import { formatPhoneTable } from '../../../core/utils/formatPhoneNumber';
import { Button, Input, Modal, Select } from 'antd';
import { useEffect, useState } from 'react';
import useFac from '../../../core/hooks/useFac';
import {
  getFacOrigin,
  getFacStatus,
} from '../../../core/utils/getFacDetails';

export default function KanBanDialog(
  props: any
): JSX.Element {
  const {
    setEditFacForm,
    getFacHistory,
    facHistory,
    editFacForm,
    updateFac,
    oldFacStatus,
    setOldFacStatus,
    createFacHistory,
  } = useFac();

  const [isCommentModalOpen, setIsCommentModalOpen] =
    useState(false);
  const [comment, setComment] = useState('');

  useEffect(() => {
    setEditFacForm({
      phoneNumber: formatPhoneTable(props?.phoneNumber),
      name: props?.name,
      status: props?.status,
    });
    setOldFacStatus(props?.status);
    getFacHistory(props.id);
  }, []);

  const statusList = [
    { name: 'Novo Contato', value: 'NEW_CONTACT' },
    {
      name: 'Contato não estabelecido',
      value: 'UNABLE_TO_CONTACT',
    },
    { name: 'Segunda Tentativa', value: 'TWO_ATTEMPT' },
    { name: 'Visita Agendada', value: 'VISIT_SCHEDULED' },
    { name: 'Contato Feito', value: 'CONTACT_TERMINATED' },
    { name: 'Sem Interesse', value: 'NOT_INTERESTED' },
    {
      name: 'Lead Desqualificada',
      value: 'LEAD_DISQUALIFIED',
    },
  ];

  return (
    <div className='card-template'>
      <div className='e-card-content'>
        <table className='card-template-wrap'>
          <tbody>
            <tr>
              <td className='CardHeader'>Nome:</td>
              <td>
                <Input
                  name='name'
                  value={editFacForm.name}
                  onChange={(e) =>
                    setEditFacForm({
                      ...editFacForm,
                      name: e.target.value,
                    })
                  }
                />
              </td>
            </tr>
            <tr>
              <td className='CardHeader'>Cidade:</td>
              <td>
                {props.city} - {props.state}
              </td>
            </tr>
            <tr>
              <td className='CardHeader'>Origem:</td>
              <td>{getFacOrigin(props.origin)}</td>
            </tr>
            <tr>
              <td className='CardHeader'>Criado em:</td>
              <td>
                {moment(props.createdAt).format(
                  'DD/MM/YYYY'
                )}
              </td>
            </tr>
            <tr>
              <td className='CardHeader'>Status:</td>
              <td>
                <Select
                  value={editFacForm.status}
                  onChange={(e) =>
                    setEditFacForm({
                      ...editFacForm,
                      status: e,
                    })
                  }
                  showSearch
                  style={{ width: '100%' }}
                  getPopupContainer={(trigger) =>
                    trigger.parentNode
                  }
                >
                  {statusList.map((status: any) => (
                    <Select.Option
                      key={status.value}
                      value={status.value}
                    >
                      {status.name}
                    </Select.Option>
                  ))}
                </Select>
              </td>
            </tr>
            <tr>
              <td className='CardHeader'>Telefone:</td>
              <td>
                <Input
                  onChange={(e) =>
                    setEditFacForm({
                      ...editFacForm,
                      phoneNumber: e.target.value,
                    })
                  }
                  value={editFacForm.phoneNumber}
                />
              </td>
            </tr>
            <tr>
              <td className='CardHeader'>Histórico:</td>
              <td>
                <Button
                  onClick={() =>
                    setIsCommentModalOpen(true)
                  }
                  type='primary'
                >
                  Ver Histórico
                </Button>
              </td>
            </tr>
            <tr>
              <td className='CardHeader'>
                <Button
                  onClick={() =>
                    updateFac(
                      props.id,
                      editFacForm,
                      oldFacStatus,
                      props.comments
                    )
                  }
                  type='primary'
                >
                  Salvar
                </Button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <Modal
        title='Histórico'
        zIndex={99000}
        open={isCommentModalOpen}
        onClose={() => setIsCommentModalOpen(false)}
        onOk={() =>
          createFacHistory(
            props.id,
            props,
            oldFacStatus,
            comment
          )
        }
        onCancel={() => setIsCommentModalOpen(false)}
      >
        {facHistory ? (
          facHistory?.map((item) => (
            <>
              <div>
                {item.comments ? (
                  <div style={{ display: 'flex' }}>
                    <p>
                      <b>{item.username}</b> comentou:{' '}
                      {item.comments} - ás{' '}
                      <i>
                        {moment(item.updatedAt).format(
                          'DD/MM/YYYY [às] HH:mm'
                        )}
                      </i>
                    </p>
                  </div>
                ) : null}
              </div>
              {item.newStatus && item.oldStatus &&
              
              <div style={{ marginBottom: 5 }}>
                <i>
                  {moment(item.updatedAt).format(
                    'DD/MM/YYYY [às] HH:mm'
                  )}
                </i>
                : <b>{item.username}</b> mudou o status
                da Lead de{' '}
                <b>{getFacStatus(item.oldStatus!)}</b> para{' '}
                <b>{getFacStatus(item.newStatus!)}</b>
              </div>
                }
            </>
          ))
        ) : (
          <div>Não há histórico para essa Lead</div>
        )}
        <p>Comentar:</p>
        <Input.TextArea
          maxLength={1000}
          placeholder='Digite seu comentário:'
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
      </Modal>
    </div>
  );
}
