import {
  KanbanComponent,
  ColumnsDirective,
  ColumnDirective,
} from '@syncfusion/ej2-react-kanban';
import '../../index.less';
import KanBanCard from '../components/KanBanCard';
import KanBanDialog from '../components/KanBanDialog';
import useFac from '../../core/hooks/useFac';
import { Skeleton } from 'antd';

export default function LeadsCRM() {
  const { facs, updateFacStatus, isLoading, setOldFacStatus } = useFac();

if(isLoading) return <Skeleton />

  return (
    <div style={{width: "100%"}}>
      <KanbanComponent
        id='kanban'
        keyField='status'
        dataSource={facs}
        cardSettings={{
          contentField: 'city',
          headerField: 'id',
          template: KanBanCard,
          }
        }
        locale="pt-BR"
        cssClass='kan-ban-container'
        dialogSettings={{
          model: {
            header: 'Visualizar Lead',
          },
          template: KanBanDialog,
        }}
        dragStart={e => setOldFacStatus(e.data[0].status)}
        dataSourceChanged={(e) => {
           updateFacStatus(e.changedRecords[0].id, {
            status: e.changedRecords[0].status,
          })
        }}
      >
        <ColumnsDirective>
          <ColumnDirective
            headerText='Novo Contato'
            keyField='NEW_CONTACT'
          />
          <ColumnDirective
            headerText='Contato não estabelecido'
            keyField='UNABLE_TO_CONTACT'
          />
          <ColumnDirective
            headerText='Segunda Tentativa'
            keyField='TWO_ATTEMPT'
          />
          <ColumnDirective
            headerText='Visita Agendada'
            keyField='VISIT_SCHEDULED'
          />
          <ColumnDirective
            headerText='Contato Feito'
            keyField='CONTACT_TERMINATED'
          />
          <ColumnDirective
            headerText='Sem Interesse'
            keyField='NOT_INTERESTED'
          />
          <ColumnDirective
            headerText='Lead Desqualificada'
            keyField='LEAD_DISQUALIFIED'
          />
        </ColumnsDirective>
      </KanbanComponent>
    </div>
  );
}
