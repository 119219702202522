export function getFacStatus(status: string) {
  switch (status) {
    case 'NEW_CONTACT':
      return 'Novo Contato';
    case 'UNABLE_TO_CONTACT':
      return 'Contato não estabelecido';
    case 'TWO_ATTEMPT':
      return 'Segunda Tentativa';
    case 'VISIT_SCHEDULED':
      return 'Visita Agendada';
    case 'CONTACT_TERMINATED':
      return 'Contato Feito';
    case 'NOT_INTERESTED':
      return 'Sem Interesse';
    case 'LEAD_DISQUALIFIED':
      return 'Lead Desqualificada';
    default:
      return 'Novo Contato';
  }
}

export function getFacOrigin(origin: string) {
  switch (origin){
    case 'MOBILE':
    return 'Aplicativo'
    case 'SITE':
    return 'Site'
    default:
      return 'Site'
  }
}